import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Image from '../components/image';
import { motion } from 'framer-motion';
import ScrollAnimation from '../components/scrollanimation';
import Modal from 'react-modal';
import Video from '../components/video';
import { Newspaper, PeopleGroup, Calendar, Planet, Play } from 'akar-icons';

Modal.setAppElement('#___gatsby');

export default function IndexPage() {
    const sentence = {
        hidden: { opacity: 1 },
        visible: {
            opacity: 1,
            transition: {
                delay: 0.5,
                staggerChildren: 0.025,
            },
        },
    };
    const letter = {
        hidden: { opacity: 0, y: 50 },
        visible: {
            opacity: 1,
            y: 0,
        },
    };

    const line1 = 'Clinic Inventory and';
    const line2 = 'Patient Treatment Management Tool';

    const start = {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.45,
            ease: 'easeInOut',
        },
    };
    const end = {
        y: '-1vw',
        opacity: 0,
        transition: {
            duration: 0.15,
            ease: 'easeInOut',
        },
    };

    const divOneAnimation = ScrollAnimation(start, end, 0.5);
    const divTwoAnimation = ScrollAnimation(start, end, 0.2);
    const divThreeAnimation = ScrollAnimation(start, end, 0.2);
    const divVidAnimation = ScrollAnimation(start, end, 0.2);

    const [modalIsOpen, setIsOpen] = React.useState(false);

    return (
        <Layout>
            <Seo
                title="Streamline your Clinic"
                description="Clinic Inventory and Patient Treatment Management Tool"
            />
            <div className="app-hero">
                <div className="container">
                    <div className="hero">
                        <div className="hero__txt">
                            <motion.h1
                                variants={sentence}
                                initial="hidden"
                                animate="visible"
                            >
                                {line1.split('').map((char, index) => {
                                    return (
                                        <motion.span
                                            key={char + '-' + index}
                                            variants={letter}
                                        >
                                            {char}
                                        </motion.span>
                                    );
                                })}
                                <br />
                                {line2.split('').map((char, index) => {
                                    return (
                                        <motion.span
                                            key={char + '-' + index}
                                            variants={letter}
                                        >
                                            {char}
                                        </motion.span>
                                    );
                                })}
                            </motion.h1>
                            <p>
                                Streamline your clinic. Real-time inventory
                                management, patient treatment and bookings.
                            </p>
                            <Link to="/demo" className="btn" id="bannerb">
                                Book a Demo
                            </Link>
                            <Link to="/features" className="btn fbtn">
                                Features
                            </Link>
                        </div>
                        <div className="hero__img">
                            <Image
                                src="title-img.png"
                                alt="Management Dashboard"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="app-content">
                <div className="container">
                    <div className="intro">
                        <div className="intro__txt" ref={divOneAnimation.ref}>
                            <motion.div animate={divOneAnimation.animation}>
                                <h1>Simplified Clinic Management</h1>
                                <p>
                                    DigiClinic keeps things simple. No need for
                                    overcomplicated features. Giving you just
                                    the right amount of features to keep your
                                    clinic running at maximum efficiency.
                                </p>
                                <Link to="/features" className="btn">
                                    Features
                                </Link>
                            </motion.div>
                        </div>
                        <div className="intro__img">
                            <Image src="inv.png" alt="Inventory Management" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="app-content vidwrap">
                <div className="container">
                    <div className="video">
                        <div className="video__item" ref={divVidAnimation.ref}>
                            <motion.div animate={divVidAnimation.animation}>
                                <div className="video__item--txt">
                                    <div>
                                        <Image
                                            src="digiclinic-logo-invert.svg"
                                            alt="Digiclinic Play"
                                        />
                                        <p>Software Video Overview</p>
                                        <button
                                            onClick={() => setIsOpen(true)}
                                            className="btn"
                                        >
                                            Watch{' '}
                                            <Play strokeWidth={2} size={24} />
                                        </button>
                                        <Modal
                                            isOpen={modalIsOpen}
                                            onRequestClose={() =>
                                                setIsOpen(false)
                                            }
                                            overlayClassName={{
                                                base: 'overlay-base',
                                                afterOpen: 'overlay-after',
                                                beforeClose: 'overlay-before',
                                            }}
                                            className={{
                                                base: 'content-base',
                                                afterOpen: 'content-after',
                                                beforeClose: 'content-before',
                                            }}
                                            closeTimeoutMS={500}
                                        >
                                            <button
                                                onClick={() => setIsOpen(false)}
                                                className="modalclose"
                                            >
                                                Close
                                            </button>
                                            <Video
                                                videoSrcURL="https://player.vimeo.com/video/731992006?h=e790efe74b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                                videoTitle="DigiClinic. Streamline your clinic."
                                            />
                                        </Modal>
                                    </div>
                                </div>
                                <Image
                                    src="digi-vid.png"
                                    alt="DigiClinic Video"
                                />
                            </motion.div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="app-content featurebg">
                <div className="container" ref={divTwoAnimation.ref}>
                    <div className="featuresheader">
                        <h1>Features Snapshot</h1>
                    </div>
                    <motion.div animate={divTwoAnimation.animation}>
                        <div className="featurescta">
                            <Link to="/features" className="featurescta__item">
                                <span className="ficon">
                                    <Newspaper strokeWidth={1} size={34} />
                                </span>
                                <h2>Real-time Inventory Management</h2>
                                <p>
                                    Keep track of your inventory quickly and
                                    easily, never run out of critical stock.
                                </p>
                            </Link>
                            <Link to="/features" className="featurescta__item">
                                <span className="ficon">
                                    <PeopleGroup strokeWidth={1} size={34} />
                                </span>
                                <h2>Patient &amp; Treatment Management</h2>
                                <p>
                                    Manage Patient and Treatment data. Current
                                    and historical data at your finger tips.
                                </p>
                            </Link>
                            <Link to="/features" className="featurescta__item">
                                <span className="ficon">
                                    <Calendar strokeWidth={1} size={34} />
                                </span>
                                <h2>Bookings, Calendar &amp; Forecasts</h2>
                                <p>
                                    Manage your week with integrated bookings,
                                    calendar and inventory forecasts.
                                </p>
                            </Link>
                            <Link to="/features" className="featurescta__item">
                                <span className="ficon">
                                    <Planet strokeWidth={1} size={34} />
                                </span>
                                <h2>Single or Multisite Management</h2>
                                <p>Manage one or multiple clinics easily.</p>
                            </Link>
                        </div>
                        <div className="featureslink">
                            <Link to="/features" className="btn">
                                More Information
                            </Link>
                        </div>
                    </motion.div>
                </div>
                <div className="container" ref={divThreeAnimation.ref}>
                    <div className="intheader">
                        <h1>Integration Options</h1>
                    </div>
                    <motion.div animate={divThreeAnimation.animation}>
                        <div className="intitems">
                            <div>
                                <Image
                                    src="int-mailchimp.png"
                                    alt="Mailchimp"
                                    className="mailc"
                                />
                            </div>
                            <div>
                                <Image
                                    src="int-myob.png"
                                    alt="Myob"
                                    className="myob"
                                />
                            </div>
                            <div>
                                <Image
                                    src="int-xero.png"
                                    alt="Xero"
                                    className="xero"
                                />
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
        </Layout>
    );
}
